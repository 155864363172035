// import React, { useState, useEffect } from 'react';
// import { Table } from 'antd';
// import axios from 'axios';

// const OwnerTransactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchTransactions = async () => {
//       const token = sessionStorage.getItem('access_token'); // Update the key here
//       console.log('Stored Token from ownerTransaction page:', token); // Debug: Check if token is present

//       if (!token) {
//         console.error('No token found in session storage');
//         setLoading(false);
//         return;
//       }

//       try {
//         // Fetch owner-specific transactions
//         const response = await axios.get('https://node.luminary.co.ke/Transaction/ownerTransactions', {
//           headers: {
//             'Authorization': `Bearer ${token}` // Ensure correct format
//           }
//         });

//         if (response.status !== 200) {
//           throw new Error('Failed to fetch transactions');
//         }

//         setTransactions(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching transactions:', error);
//         setLoading(false);
//       }
//     };

//     fetchTransactions();
//   }, []); // Empty dependency array means this effect runs once on component mount

//   const columns = [
//     {
//       title: 'Transaction ID',
//       dataIndex: 'transaction_id',
//       key: 'transaction_id',
//     },
//     {
//       title: 'Amount',
//       dataIndex: 'amount',
//       key: 'amount',
//     },
//     {
//       title: 'Timestamp',
//       dataIndex: 'timestamp',
//       key: 'timestamp',
//       render: (text) => new Date(text).toLocaleString(),
//     },
//     {
//       title: 'Combine ID',
//       dataIndex: ['combine', 'combine_id'],
//       key: 'combine_id',
//     },
//     {
//       title: 'Location',
//       dataIndex: ['combine', 'location'],
//       key: 'location',
//     },
//     {
//       title: 'Start Date',
//       dataIndex: ['combine', 'startDate'],
//       key: 'startDate',
//       render: (text) => new Date(text).toLocaleDateString(),
//     },
//     {
//       title: 'End Date',
//       dataIndex: ['combine', 'endDate'],
//       key: 'endDate',
//       render: (text) => new Date(text).toLocaleDateString(),
//     },
//     {
//       title: 'Category',
//       dataIndex: ['combine', 'category'],
//       key: 'category',
//     },
//     {
//       title: 'Paystack Transaction ID',
//       dataIndex: 'paystack_transaction_id',
//       key: 'paystack_transaction_id',
//     },
//   ];

//   if (loading) {
//     return <div className='display-1 mt-5 mb-5'>Loading...</div>;
//   }

//   return (
//     <div>
//       <h2>Transaction History</h2>
//       <Table dataSource={transactions} columns={columns} rowKey="transaction_id" />
//     </div>
//   );
// };

// export default OwnerTransactions;




import React, { useState, useEffect } from 'react';
import { Table, Statistic, Row, Col, Select, DatePicker } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const OwnerTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filterCategory, setFilterCategory] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const token = sessionStorage.getItem('access_token'); // Update the key here
      console.log('Stored Token from ownerTransaction page:', token); // Debug: Check if token is present

      if (!token) {
        console.error('No token found in session storage');
        setLoading(false);
        return;
      }

      try {
        // Fetch owner-specific transactions
        const response = await axios.get('https://node.luminary.co.ke/Transaction/ownerTransactions', {
          headers: {
            'Authorization': `Bearer ${token}` // Ensure correct format
          }
        });

        if (response.status !== 200) {
          throw new Error('Failed to fetch transactions');
        }

        setTransactions(response.data);
        setLoading(false);

        // Calculate total amount
        const total = response.data.reduce((acc, transaction) => acc + transaction.amount, 0);
        setTotalAmount(total);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []); // Empty dependency array means this effect runs once on component mount

  const handleCategoryChange = (value) => {
    setFilterCategory(value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const filteredTransactions = transactions.filter(transaction => {
    const matchesCategory = filterCategory ? transaction.combine.category === filterCategory : true;
    const matchesDateRange = dateRange.length > 0 ? moment(transaction.timestamp).isBetween(dateRange[0], dateRange[1]) : true;
    return matchesCategory && matchesDateRange;
  });

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Combine ID',
      dataIndex: ['combine', 'combine_id'],
      key: 'combine_id',
    },
    {
      title: 'Location',
      dataIndex: ['combine', 'location'],
      key: 'location',
    },
    {
      title: 'Start Date',
      dataIndex: ['combine', 'startDate'],
      key: 'startDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: ['combine', 'endDate'],
      key: 'endDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Category',
      dataIndex: ['combine', 'category'],
      key: 'category',
    },
    {
      title: 'Paystack Transaction ID',
      dataIndex: 'paystack_transaction_id',
      key: 'paystack_transaction_id',
    },
  ];

  if (loading) {
    return <div className='display-1 mt-5 mb-5'>Loading...</div>;
  }

  return (
    <div>
      <h2>Transaction History</h2>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Total Amount" value={totalAmount} precision={2} />
        </Col>
        <Col span={6}>
          <Select
            placeholder="Filter by Category"
            onChange={handleCategoryChange}
            allowClear
            style={{ width: '100%' }}
          >
            <Option value="Category1">Category1</Option>
            <Option value="Category2">Category2</Option>
            <Option value="Category3">Category3</Option>
            {/* Add more categories as needed */}
          </Select>
        </Col>
        <Col span={12}>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Table dataSource={filteredTransactions} columns={columns} rowKey="transaction_id" style={{ marginTop: 20 }} />
    </div>
  );
};

export default OwnerTransactions;
