import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import axios from "axios";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const ownerEmail = sessionStorage.getItem('ownerEmail');

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Email',
      dataIndex: ['customer', 'email'],
      key: 'email',
    },
    // Add more columns as needed
  ];

  useEffect(() => {
    // Fetch transactions data from backend or Paystack API
    const fetchTransactions = async () => {
      try {
        // Replace with actual endpoint to fetch transactions
        const response = await axios.get('https://node.luminary.co.ke/payment/transactions', {
          params: { email: ownerEmail } // Pass adminEmail as a query parameter
        });

        // axios does not have an `ok` property
        if (response.status !== 200) {
          throw new Error('Failed to fetch transactions');
        }

        // Access the actual data from the response
        const data = response.data.data;
        console.log(data);
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [ownerEmail]); // Run useEffect whenever adminEmail changes

  return (
    <div>
      <h2>Transaction History</h2>
      <Table dataSource={transactions} columns={columns} rowKey="id" />
    </div>
  );
};

export default Transactions;

